.w-50{
    width: 50%;
}
.w-100{
    width: 100%;
}
.c-blue{
    color: #0099ff;
}
.b-blue{
    background: #0099ff;
}
.pointer{
    cursor: pointer;
}
