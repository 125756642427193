html {
    font-size: 115%;
}

.appBar {
    position: sticky;
    width: 100vw;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1em 0 1em 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.0625rem 0.5rem 0px, rgba(0, 0, 0, 0.14) 0px 0.1875rem 0.25rem 0px, rgba(0, 0, 0, 0.12) 0px 0.1875rem 0.1875rem -0.125rem;
    background: white;
    z-index: 900;
}

.headText {
    font-weight: bolder;
    font-size: 125%;
    padding: 0.1em 0 0.1em 1.2em;
    margin: 0;
}

.actionBtn {
    color: white;
    font-weight: bold;
    font-size: 110%;
    position: absolute;
    right: 0.7em;
    background-color: #0099ff;
    border-radius: 2px;
    padding: 0.225em 1.2em 0.215em 1.2em;
    text-decoration: none;
}

.icon {
    color: #0099ff;
    padding: 0 0 0 0.7em
}

.iconLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.menu {
    width: 50%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background: white;
    top: 0;
    border: 1px solid #e5e5e5;
    border-top: 1px solid #fff;
    left: 0;
    transition: left 0.3s;
    max-width: 250px;
}

.menuHidden {
    width: 50%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background: white;
    top: 0;
    border: 1px solid #e5e5e5;
    border-top: 1px solid #fff;
    left: calc(-50% - 2px);
    transition: left 0.3s;
    max-width: 250px;
}

.menuLink {
    text-decoration: none;
    padding: 0.5em 0.5em 0.5em 0.7em;
    font-weight: 550;
    color: black;
    font-size: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menuLink:hover{
    background: #e6e6e6;
}

.menuCloserVisible {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 199;
}

.menuCloserHidden {
    display: none !important;
}

.divider {
    background: #e5e5e5;
    height: 1px;
    width: 100%;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(6px);
}

.modalBlock {
    width: 85%;
    max-width: 500px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0.0625rem 0.5rem 0px, rgba(0, 0, 0, 0.14) 0px 0.1875rem 0.25rem 0px, rgba(0, 0, 0, 0.12) 0px 0.1875rem 0.1875rem -0.125rem;
    border-radius: 3px;
    margin: auto;
    padding: 0.6em;
    background: white;
}

.modalText {
    font-size: 130%;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 0.3em;
}

.modalButtonBar {
    margin-top: 0.5em;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.modalButton {
    font-weight: bolder;
    font-size: 110%;
    padding: 0.3em 2em 0.3em 2em;
    color: black;
    background: white;
    border: 1px solid black;
    border-radius: 2px;
    margin-right: 0.3em;
}

.modalPrimaryButton {
    font-weight: bolder;
    font-size: 110%;
    padding: 0.3em 2em 0.3em 2em;
    color: white;
    background: #0099ff;
    border: 1px solid #0099ff;
    border-radius: 2px;
}

.modalPrimaryButton:hover {
    background: #007acc;
}

.showMoreCloser{
    width: 100%;
    height:100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.showMore{
    z-index: 9999;
    position: absolute;
    right: 5px;
    top:2.5em;
    padding: 0.3em;
    background: white;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.0625rem 0.5rem 0px, rgba(0, 0, 0, 0.14) 0px 0.1875rem 0.25rem 0px, rgba(0, 0, 0, 0.12) 0px 0.1875rem 0.1875rem -0.125rem;

}

.showMoreList{

}

.showMoreItem{
    padding:0.4em 0.2em 0.4em 0.2em;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.showMoreItem:hover{
    background: #e6e6e6;
}

.showMoreItem:active{
    background: #d9d9d9;
}

.showMoreIcon{
    padding-right: 0.1em;
}

.iconButton{
    padding:0.5em;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.iconButton:hover{
    background: #e6e6e6;
}

.iconButton:active{
    background: #d9d9d9;
}

.tabViewButtonBar {
    display: flex;
    background: white;
    z-index: 901;
}

.tabViewButton {
    width: 50%;
    text-align: center;
    padding: 0.4em;
    background: white;
    z-index: 50;
    text-transform: uppercase;
    line-height: 1.8em;
}

.tabViewButtonActive {
    color: #0099ff;
}

.tabViewActiveBar {
    width: 50%;
    position: relative;
    left: 0;
    height: 2px;
    background: #0099ff;
    transition: left 0.2s;
}

.tabViewActiveBar1 {
    left: 50%;
}

.tabViewContent {
    width: 200%;
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 0;
    transition: left 0.2s;
    max-height: 100% !important;
    overflow: hidden;
}

.tabViewContent1 {
    left: -100% !important;
}

.tabViewPage {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 100% !important;
    flex-grow: 10;
}

.labelInputBox {
    padding: 0.4em 0.5em 0 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 1em);
}

.labelInputBoxCheck {
    padding: 1em 0.5em 0 0.5em !important;
}

.textInput {
    padding: 0.3em;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    font-size: 105%;
}

.input:focus {
    border: 1px solid #0099ff;
    outline: none;
}

.selectInput {
    outline: none;
    border-radius: 3px;
    padding: 0.3em;
    width: 50%;
    border: 1px solid #e6e6e6;
    font-size: 105%;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background: white;
}

.ctrlList {

}

.ctrlListItem {
    border-bottom: 1px solid #e5e5e5;
    font-weight: bolder;
    cursor:pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.ctrlListInfo{
    padding: 0.5em 0.2em 0.5em 0.2em;
    flex-grow: 1;
}

.ctrlListItem:hover{
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)
}

.ctrlListMore{
    z-index:5;
}

.ctrlListTime {
    font-size: 75%;
    color: grey;
}

.isDraft {
    color: red;
}

.contentBox {
    padding: 0.5em;
}

.weatherBar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.weatherBarX {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.weatherColumn {
    display: flex;
    flex-direction: column;
    padding-left: 0.3em;
}

.weatherMain {
    font-size: 260%;
    font-weight: bolder;
}

.weatherSub {
    font-size: 90%;
}

.weatherRefreshButton {
    font-size: 200%;
}

.weatherDescription {
    font-size: 120%;
    font-weight: bolder;
}

.subHeader {
    font-size: 110%;
    font-weight: bolder;
}

.weatherAlertTitle {
    color: red;
    font-weight: bolder;
}

.page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: #fff;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    width:100%;
    max-width: 850px;
    margin:auto;
    background: white;
    border-left: 1px solid rgb(219, 219, 219);
    border-right: 1px solid rgb(219, 219, 219);
    overflow-y: auto;
}

.tabViewPos {
    flex-grow: 10;
    position: relative;
    overflow: hidden;
}

.hsCard {
    padding: 0.3em;
    margin: 0.4em;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 22%);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hsCardInfoBox {
    display: flex;
    align-items: center;
}

.hsCardTimeInfo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 1em;
}

.hsCardTimeText {
    font-weight: bolder;
    font-size: 95%;
}

.hsCardNameInfo {
    font-weight: bold;
    font-size: 130%;
}

.expandButton {
    font-size: 200%;
    cursor: pointer;
}

.deleteButton {
    color: red;
    text-decoration: underline;
    cursor:pointer;
}

.cardButtonBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4em 0 0 0.5em;
}

.hsCardE {
    padding: 0.3em;
    margin: 0.4em;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 22%);
    border-radius: 3px;
}

.hsCardNameInputBox {
    padding: 0.4em 0.5em 0 0.5em;
}

.hsCardNameInput {
    width: calc(100% - 2px);
    font-weight: bold;
    font-size: 155%;
    padding: 0;
}

.checkbox {
    -webkit-appearance: none;
    min-width: 22px;
    min-height: 22px;
    background: white;
    border-radius: 3px;
    border: 1px solid #cccccc;
    margin: 0 0 0 1em;
}

.checkbox:checked {
    background: #0099ff;
}

.checkbox:focus {
    outline: none;
}

.extraButton {
    margin: 1% 10% 1% 10%;
    width: 80%;
    outline: none;
    background: #00cc00;
    border: none;
    padding: 0.5em 0.8em 0.5em 0.8em;
    color: white;
    border-radius: 3px;
    font-weight: bolder;
    font-size: 105%;
}

.loginFormBox{
    width:100%;
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
}
.loginForm{
    display: flex;
    flex-direction: column;
    width:80%;
    margin: 0 10% 0 10%;
    max-width: 350px;
}
.loginTitle{
    text-align: center;
    font-weight: bolder;
    font-size: 125%;
    margin: 0;
    padding-bottom: 0.5em;
}
.loginInput{
    margin-top: 0.3em;
}
.loginButton{
    color: white;
    border:none;
    background: #0099ff;
    padding: 0.3em;
    border-radius: 3px;
    font-size: 105%;
    font-weight: bolder;
    outline: none;
    cursor:pointer;
}
.showPasswordBox{
    display: flex;
    justify-content: flex-end;
        margin-top: 0.3em;
}
.showPassword{
    cursor: pointer;
    font-size: 80%;
    color: grey;
    padding: 0.3em 0.1em 0.3em 0.1em;
    border-radius: 3px;
}
.showPassword:hover{
    background: #e6e6e6;
}
.showPassword:active{
    background: #d9d9d9;
}
.loadingSpinnerBox{
    width:100%;
    display:flex;
    align-items: center;
    flex-direction: column;
}
.loadingSpinner{
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingSpinner div {
  position: absolute;
  border: 4px solid #e6e6e6;
  opacity: 1;
  border-radius: 50%;
  animation: loadingSpinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loadingSpinner div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loadingSpinner {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.loadingSpinnerText{
    color: #e6e6e6;
}

.autocompleteBox{
    position: fixed;
}
