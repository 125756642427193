.hsCard{
    display: flex;
    flex-direction: row;
}
.hsNameInputBox{
    width: calc(100% - 1.6em - 2px);
    padding: 0.4em 0.5em 0 0.5em;
}
.hsEditButton{
    position: relative; right: 0;
}
